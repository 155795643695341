import axios from 'axios'

export const subscribeAPI = async (email) => {
	const url = process.env.REACT_APP_SUBSCRIBE_URL

	console.log('url:', url)
	console.log('email:', email)

	const payload = {
		email,
	}

	const res = await axios.post(url, payload)

	return res.data
}

export const unsubscribeAPI = async (email) => {
	const url = process.env.REACT_APP_UNSUBSCRIBE_URL

	console.log('url:', url)
	console.log('email:', email)

	const payload = {
		email,
	}

	try {
		const res = await axios.post(url, payload)

		return res.data
	} catch (e) {
		return {
			message: 'doesnt exist',
		}
	}
}

export const getTopicsAPI = async () => {
	const url = process.env.REACT_APP_GET_TOPICS_URL

	const res = await axios.get(url)

	return res.data
}

export const createAnalyticsEventAPI = async (eventName, eventData) => {
	const payload = {
		eventName,
		eventData,
	}

	const url = process.env.REACT_APP_ANALYTICS_URL

	const res = await axios.post(url, payload)

	return res.data
}
