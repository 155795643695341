import { useState } from 'react'
import { useApi, useNavigation } from '../contexts'

export const Unsubscribe = () => {
	const [text, setText] = useState('')

	const { setNavigation, NAV } = useNavigation()
	const { isLoading, unsubscribe } = useApi()

	const handleOnClick = async () => {
		await unsubscribe(text)
		setText('')
		setNavigation(NAV.LANDING)
	}

	return (
		<div className="fc cc fh fw gap50">
			<div className="fc cc fh fw80">
				<h4 className="fw80 ta">
					To cancel your subscription please proceed to{' '}
					<a href={process.env.REACT_APP_STRIPE_PORTAL_URL}>
						Manage billing
					</a>
				</h4>
				<h5 className="ta">
					To stop receiving emails immediately enter your email below
				</h5>
				<div className="fc sac fw80 gap50">
					<input
						type="email"
						placeholder="Enter your email"
						className="fc cc"
						value={text}
						onChange={(e) => setText(e.target.value)}
					/>
					<button
						className="fc cc"
						onClick={handleOnClick}
						disabled={isLoading}
					>
						Unsubscribe
					</button>
				</div>
			</div>
		</div>
	)
}
