import { useNavigation } from './contexts'
import { Landing, Unsubscribe } from './pages'

export const App = () => {
	const { navigation, NAV } = useNavigation()

	return (
		<div className="fh fw">
			{navigation === NAV.LANDING && <Landing />}
			{navigation === NAV.UNSUBSCRIBE && <Unsubscribe />}
		</div>
	)
}
