import { createContext, useContext, useEffect, useState } from 'react'

const NavigationContext = createContext()

const NAV = {
	LANDING: 'landing',
	UNSUBSCRIBE: 'unsubscribe',
	SUCCESS: 'success',
}

export const NavigationProvider = ({ children }) => {
	const [navigation, setNavigation] = useState(NAV.LANDING)

	useEffect(() => {
		if (window.location.pathname === '/unsubscribe') {
			setNavigation(NAV.UNSUBSCRIBE)
		}
	}, [])

	return (
		<NavigationContext.Provider
			value={{
				navigation,
				setNavigation,
				NAV,
			}}
		>
			{children}
		</NavigationContext.Provider>
	)
}

export const useNavigation = () => {
	const context = useContext(NavigationContext)
	return context
}
