import { useEffect } from 'react'
import { useNavigation, useApi } from '../contexts'

export const Landing = () => {
	const { setNavigation, NAV } = useNavigation()
	const { isLoading, latestTopic, createAnalyticsEvent } = useApi()

	const handleOnClick = async () => {
		// navigate to external link
		window.location.href = process.env.REACT_APP_STRIPE_PAYMENT_URL
	}

	useEffect(() => {
		console.log('page mounted')
		const pageMounted = async (url) => {
			const query = new URLSearchParams(window.location.search)

			const referrer = query.get('r')

			const eventData = {
				url,
				referrer,
			}

			await createAnalyticsEvent('landing-page-view', eventData)
		}

		pageMounted(window.location.href)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="fc sc fh fw gap50 scrolly">
			<div className="fc cc fh20 fw80 noshrink">
				<h1 className="fc cc fw fh ta">One New Thing</h1>
				<h4 className="fc cc fw fh ta">
					The daily newsletter designed to broaden your knowledge in
					software engineering and computer science, one topic at a
					time.
				</h4>
			</div>

			<div className="fc cc fh20 fw80 noshrink">
				<div className="fr cc fw fh">
					<h1 className="ta">$1/month</h1>
					<h4 className="ta">AUD</h4>
				</div>
			</div>

			<div className="fc sac fh20 fw80 noshrink">
				<button
					className="fc cc"
					onClick={handleOnClick}
					disabled={isLoading}
				>
					Sign up
				</button>
			</div>

			<div className="fc cc fw80 noshrink">
				{latestTopic && (
					<div className="fc cc fw">
						<h4 className="ta">Yesterday's Edition:</h4>
						<h5 className="ta">{latestTopic.title}</h5>
						<h4 className="ta">Introduction</h4>
						<p className="tal">{latestTopic.introduction}</p>
						<h4 className="ta">Overview</h4>
						<p className="tal">{latestTopic.overview}</p>
						<h4 className="ta">Importance</h4>
						<p className="tal">{latestTopic.importance}</p>
						<h4 className="ta">Historical Context</h4>
						<p className="tal">{latestTopic.historicalContext}</p>
						<h4 className="ta">Use Cases</h4>
						<ul>
							{latestTopic.useCases.map((useCase, i) => (
								<li key={i} className="tal">
									{useCase}
								</li>
							))}
						</ul>
						<h4 className="ta">Pros</h4>
						<ul>
							{latestTopic.pros.map((pro, i) => (
								<li key={i} className="tal">
									{pro}
								</li>
							))}
						</ul>
						<h4 className="ta">Cons</h4>
						<ul>
							{latestTopic.cons.map((con, i) => (
								<li key={i} className="tal">
									{con}
								</li>
							))}
						</ul>
						<h4 className="ta">Key Takeaways</h4>
						<ul>
							{latestTopic.keyTakeaways.map((keyTakeaway, i) => (
								<li key={i} className="tal">
									{keyTakeaway}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>

			<div className="fc cc fh20 fw80 padbottom50 noshrink">
				<a
					href=""
					className="fc cc fh fw"
					onClick={(e) => {
						e.preventDefault()
						setNavigation(NAV.UNSUBSCRIBE)
					}}
				>
					manage subscription
				</a>
			</div>
		</div>
	)
}
