import { createContext, useContext, useEffect, useState } from 'react'
import {
	createAnalyticsEventAPI,
	getTopicsAPI,
	subscribeAPI,
	unsubscribeAPI,
} from '../services'

const APIContext = createContext()

export const APIProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [latestTopic, setLatestTopic] = useState(null)

	const subscribe = async (email) => {
		setIsLoading(true)
		const res = await subscribeAPI(email)
		setIsLoading(false)
	}

	const unsubscribe = async (email) => {
		setIsLoading(true)
		const res = await unsubscribeAPI(email)
		setIsLoading(false)
	}

	const getTopics = async () => {
		setIsLoading(true)
		const res = await getTopicsAPI()
		setLatestTopic(JSON.parse(res.topic.content.S))
		setIsLoading(false)
	}

	const createAnalyticsEvent = async (eventName, eventData) => {
		await createAnalyticsEventAPI(eventName, eventData)
	}

	useEffect(() => {
		getTopics()
	}, [])

	return (
		<APIContext.Provider
			value={{
				isLoading,
				subscribe,
				unsubscribe,
				latestTopic,
				createAnalyticsEvent,
			}}
		>
			{children}
		</APIContext.Provider>
	)
}

export const useApi = () => {
	const context = useContext(APIContext)
	return context
}
